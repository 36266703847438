import React from "react"
import styles from "./footer.module.css"
import fbIcon from "../images/facebook-icon.svg"
import twitterIcon from "../images/twitter-icon.svg"
import linkedinIcon from "../images/linkedin-icon.svg"

const Footer = () => {
  return (
    <footer id="footer">
      <div className={styles.content}>
        <h2>Contacto</h2>
        <div className={styles.cont1}>
          <address>
            Herminio Maldonado Nº 844 entre Lillo y Sucre Barrio San Cristobal.{" "}
            <span>Asunción - Paraguay</span>
          </address>
          <a
            href="https://g.page/Estudiotfz?share"
            className={styles.googleLink}
          >
            Como llegar - Google maps
          </a>
        </div>
        <div className={styles.cont2}>
          <a href="">contacto@estudiotfz.com</a>
          <span>+595 981 619000</span>
          <div className={styles.socialIcons}>
            <a
              href="https://www.facebook.com/estudioturbaux"
              target="_blank"
              rel="noopener"
            >
              <img src={fbIcon} alt="facebook-icon" />
            </a>
            <a
              href="https://twitter.com/Estudioturbaux"
              target="_blank"
              rel="noopener"
            >
              <img src={twitterIcon} alt="twitter-icon" />
            </a>
            <a
              href="http://linkedin.com/company/estudiotfz"
              target="_blank"
              rel="noopener"
            >
              <img src={linkedinIcon} alt="linkedin-icon" />
            </a>
          </div>
        </div>
        <div className={styles.disclaimer}>
          Estudio Juridico TFZ - © Copyright {new Date().getFullYear()} · Todos
          los derechos reservados. Un trabajo del{" "}
          <a href="https://estudioobliquo.com" target="_blank" rel="noopener">
            Estudio Obliquo
          </a>
        </div>
      </div>
    </footer>
  )
}

export default Footer
