import { Link } from "gatsby"
import PropTypes from "prop-types"
import React, { useEffect, useState } from "react"
import Menu from "./menu"
import styles from "./header.module.css"
import logo from "../images/logo.svg"
import logoCompleto from "../images/logoCompleto.svg"
import { Location } from "@reach/router"

const Header = ({ isIndex }) => {
  const [path, setPath] = useState("") //El path de la pagina actual
  useEffect(() => {
    setPath(window.location.pathname)
  }, [])

  const activeLink = { color: "#D19B20" }

  return (
    <header className={styles.header}>
      <div className={styles.subContainer1}>
        <div className={styles.logoContainer}>
          <Link to="/">
            {isIndex ? (
              <img
                className={styles.logoContainerIndex}
                src={logo}
                alt="logo"
              />
            ) : (
              <img src={logoCompleto} alt="logo" />
            )}
          </Link>
        </div>
      </div>
      <div className={styles.menuDesktop}>
        <div className={styles.linksContainer}>
          <Link className={path === "/" ? styles.active : ""} to="/">
            Inicio
          </Link>
          <Link
            className={path === "/nosotros" ? styles.active : ""}
            to="/nosotros"
          >
            Quiénes Somos
          </Link>
          <Link
            className={path === "/areas-de-practica" ? styles.active : ""}
            to="/areas-de-practica"
          >
            Áreas de Práctica
          </Link>
          <a href="#footer">Contacto</a>
        </div>

        {/* TODO: Uncomment when languages ready */}
        {/* <div className={styles.idiomasContainer}>
          <Link to={path} activeStyle={activeLink}>
            ES
          </Link>
          <Link to={path.replace("/", "/en/")} activeStyle={activeLink}>
            EN
          </Link>
          <Link to={path.replace("/", "/fr/")} activeStyle={activeLink}>
            FR
          </Link>
          <Link to={path.replace("/", "/de/")} activeStyle={activeLink}>
            DE
          </Link>
        </div> */}
      </div>
      <Menu path={path} />
    </header>
  )
}

Header.propTypes = {
  siteTitle: PropTypes.string,
}

Header.defaultProps = {
  siteTitle: ``,
}

export default Header
