import React from "react"
import PropTypes from "prop-types"
import { useStaticQuery, graphql } from "gatsby"
import styled, { css } from "styled-components"
import Header from "./header"
import Footer from "./footer"
import "./layout.css"
import "typeface-noto-serif-jp"
import "typeface-noto-sans"

const MainContainer = styled.div`
  margin: 0 auto;
  max-width: 1195px;
  padding: 0 1.1rem 1.45rem;
  background-color: var(--azul);

  @media only screen and (min-width: 1000px) {
    padding: 0 60px 1.45rem;
  }

  ${({ noContain }) =>
    noContain &&
    css`
      display: flex;
      flex-direction: column;
      max-width: 100%;
      margin: 0;
      padding: 0;
      @media only screen and (min-width: 1000px) {
        padding: 0;
      }
    `}
`

const Layout = ({ children, noContain, isIndex }) => {
  const data = useStaticQuery(graphql`
    query SiteTitleQuery {
      site {
        siteMetadata {
          title
        }
      }
    }
  `)

  return (
    <>
      <Header siteTitle={data.site.siteMetadata.title} isIndex={isIndex} />
      <MainContainer noContain={noContain}>{children}</MainContainer>
      <Footer />
    </>
  )
}

Layout.propTypes = {
  children: PropTypes.node.isRequired,
}

export default Layout
